import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Menu, MenuGroup, Sidebar } from 'react-admin-base-nazox';
import { useUser } from './Components/UserProvider';
import { useFetch } from 'react-admin-base';
import tamedLogo from './FileTemplatesAndImages/ta3meed-logo.png';
import frayLogo from './FileTemplatesAndImages/1b.png';
import { Alerts } from './Components/Alerts';
import { icons } from './Icons/icons';

export default function MenuSidebar({ setAppLogo }) {
  const user = useUser();
  const userId = user.id;
  const [company, loading] = useFetch('/company/get/user/' + userId);
  const companyCode = company && company.code;

  // const isTaemedbnpl = companyCode === 'ta3meedbnpl';
  // const isStandart = companyCode === 'standart';
  // const isAktifBank = companyCode === 'aktifbank';
  // const isTbaydin = companyCode === 'tbaydin';
  // const isParam = companyCode === 'param';
  // const isTeb = companyCode === 'teb';
  // const isUsulVentures = companyCode === 'usulventures';
  // const isSocar = companyCode === 'socar';

  useEffect(() => {
    if (companyCode === 'ta3meed') setAppLogo(tamedLogo);
    else setAppLogo(frayLogo);
  }, [company]);

  return (
    <Sidebar>
      {!loading ? (
        <>
          {companyCode !== 'ta3meedbnpl' && (
            <MenuGroup title={<FormattedMessage id="CUSTOMER" />}>
              <Menu
                title={<FormattedMessage id="CUSTOMERS" />}
                to="/customers"
                icon="fas fa-users"
              />

              <Menu
                title={<FormattedMessage id="Groups" />}
                to="/groups"
                icon="fas fa-users"
              />
            </MenuGroup>
          )}
          <MenuGroup title={<FormattedMessage id="ANALYSIS_SIDEBAR_TITLE" />}>
            {companyCode !== 'ta3meedbnpl' && (
              <Menu
                title={<FormattedMessage id="START_NEW_ANALYSIS_SIDEBAR" />}
                to="/analysis/create"
                icon="fas fa-chart-pie"
              />
            )}
            <Menu
              title={<FormattedMessage id="PREVIOUS_CREDIT_ANALYZES" />}
              to="/analyzes"
              icon="fas fa-file-contract"
            />
            {companyCode !== 'standart' && companyCode !== 'ta3meedbnpl' && (
              <Menu
                title={<FormattedMessage id="PREV_ANALYZES_GRAPHS" />}
                to="/prev-analyzes"
                icon="fas fa-file-contract"
              />
            )}
            {user.type >= 128 && (
              <>
                <Menu
                  title={<FormattedMessage id="ALL_ANALYZES" />}
                  to="/analyzes-admin"
                  icon="fas fa-file-signature"
                />
                {companyCode === 'aktifbank' && (
                  <Menu
                    title={<FormattedMessage id="FILTER_MODULE" />}
                    to="/analyzes-admin/filter"
                    icon={icons.filter}
                  />
                )}
              </>
            )}
            {(companyCode === 'aktifbank' ||
              companyCode === 'param' ||
              companyCode === 'teb' ||
              companyCode === 'usulventures' ||
              companyCode === 'tbaydin' ||
              companyCode === 'tai' ||
              companyCode === 'cfopartners') && (
              <Menu
                title={<FormattedMessage id="CONSOLIDATION" />}
                to="/consolidation"
                icon="fas fa-users"
              />
            )}
            {(companyCode === 'aktifbank' ||
              companyCode === 'param' ||
              companyCode === 'teb' ||
              companyCode === 'usulventures' ||
              companyCode === 'tbaydin' ||
              companyCode === 'tai') && (
              <Menu
                title={<FormattedMessage id="DETECTION" />}
                to="/detection/create"
                icon="fas fa-chart-pie"
              />
            )}

            {(companyCode === 'aktifbank' ||
              companyCode === 'param' ||
              companyCode === 'teb') && (
              <Menu
                title={<FormattedMessage id="RATES" />}
                to="/rates"
                icon="fas fa-chart-pie"
              />
            )}
            {(companyCode === 'socar' || companyCode === 'param') && (
              <Menu
                title={'SPK - MASAK Yasaklılar Listesi'}
                to="/spk-masak"
                icon="fas fa-chart-pie"
              />
            )}
          </MenuGroup>
          {/* <MenuGroup title={'Extra'}>
            <Menu
              title={'Halka Açık Şirketler'}
              to="/public-companies"
              // icon="fas fa-user"
            />
          </MenuGroup> */}
          {user && user.type !== 1 && (
            <MenuGroup title={<FormattedMessage id="PERSONAL_SIDEBAR_TITLE" />}>
              <Menu
                title={<FormattedMessage id="PROFILE_AND_REMAINING_RIGHT" />}
                to="/profile"
                icon="fas fa-user"
              />
            </MenuGroup>
          )}

          {user.type >= 256 && (
            <MenuGroup title={<FormattedMessage id="ADMIN_PANEL" />}>
              {user.type >= 256 && (
                <Menu
                  title={<FormattedMessage id="USERS" />}
                  to="/user"
                  icon="fas fa-users"
                />
              )}
              {user.type === 512 && (
                <Menu
                  title={<FormattedMessage id="ADMIN PANEL" />}
                  to="/extra-page"
                  icon="fas fa-plus"
                />
              )}
            </MenuGroup>
          )}
        </>
      ) : (
        <Alerts
          color="primary"
          className="fas fa-spin fa-spinner"
          messageId={'LOADING'}
          label=""
        />
      )}
    </Sidebar>
  );
}
